import Button from '@berlitz/button'
import { H1, H3 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import { AccessDisabledModal } from '@components/Modal/interface'
import { CenterText } from '@components/Modal/style'
import Router from 'next/router'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

export const Image = styled.div`
  padding: ${({ theme }) => theme.space.md};
  max-width: 250px;
`

const AccessDisabled = ({ open, content = {} }: AccessDisabledModal) => {
  const [openModal, toggleModal] = useState(open)

  const signoutUser = () => {
    toggleModal(false)
    Router.push('/signout')
  }

  return (
    <Modal open={!!openModal} onClose={signoutUser}>
      <CenterText>
        <Image>
          <img src="/images/locked.svg" width={'100%'} />
        </Image>
        <H1 disableMargin>
          <FormattedMessage id={content.header || 'Access disabled.'} />
        </H1>
        <Spacer size="md" />
        <H3>
          <FormattedMessage
            id={content.p1 || 'Your contract has ended and access to the instructor portal has been disabled.'}
          />
        </H3>
        <H3>
          <FormattedMessage id={content.p2 || 'Please contact your learning centre for more information.'} />
        </H3>
        <Button onClick={signoutUser}>
          <FormattedMessage id={content.button || 'Ok'} />
        </Button>
      </CenterText>
    </Modal>
  )
}

export default React.memo(AccessDisabled)
