import Head from 'next/head'
import React from 'react'

interface CustomPageMetaProps {
  children: React.ReactNode
  configId: string
}

const CustomPageMeta = ({ children, configId }: CustomPageMetaProps) => (
  <>
    <Head>
      <meta name="portals::permission" content={configId} />
    </Head>
    {children}
  </>
)

export default CustomPageMeta
