import { Warning } from '@berlitz/icons'
import React from 'react'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { EmptyPreferred } from './style'

const ErrorPlaceholder: React.FC<{ msg: string } & WrappedComponentProps> = ({
  msg = 'An unexpected error has occurred.',
}) => (
  <EmptyPreferred>
    <Warning size="16px" />
    <FormattedMessage id={msg} />
  </EmptyPreferred>
)

export default injectIntl(ErrorPlaceholder)
