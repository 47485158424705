import { IPermissions } from '@interfaces/Permissions'
import { IPermissionsGroups, StoreProps } from '@interfaces/StoreState'
import { UserProfileProps } from '@interfaces/UserProfile'
import { getCurrentPermission } from '@utils/helpers/permissions'
import React from 'react'
import { withApollo, WithApolloClient } from 'react-apollo'
import { connect } from 'react-redux'

export type Props = WithApolloClient<{
  roles: string[]
  userName: string
  profile?: UserProfileProps
  permissions: IPermissionsGroups
}>

export interface PermissionsProps {
  validPermission?: boolean
  showSiteMaintenance?: boolean
  currentPermission: IPermissions
}

export default function withCurrentPermission<T extends PermissionsProps>(
  Component: React.ComponentType<T>,
  params: { id: string; includeSiteMaintenance?: boolean }
) {
  class WithCurrentPermissionHOC extends React.Component<Props> {
    checkPermissions = (permissionId: string) => {
      const permission = this.props.permissions?.[permissionId]
      return {
        valid: getCurrentPermission({
          roles: this.props.roles,
          userPermission: permission,
          userName: this.props.userName,
          profile: this.props.profile,
        }),
        permission,
      }
    }

    render() {
      const currentPermission = this.checkPermissions(params.id)
      const permissionProps = {
        validPermission: currentPermission.valid,
        currentPermission: currentPermission.permission,
      }

      const sitePermission = this.checkPermissions(process.env.SITE_MAINTENANCE_ID || '')
      const isMaintenanceManager = this?.props?.permissions?.['flag::MaintenanceManagers']?.allowedUsers?.some(
        (user) => user?.toLowerCase() === this?.props?.userName?.toLowerCase()
      )

      const siteMaintenanceProps = params.includeSiteMaintenance && {
        showSiteMaintenance: !sitePermission.valid && !isMaintenanceManager,
      }
      return <Component {...(permissionProps as T)} {...(siteMaintenanceProps as T)} />
    }
  }

  const mapStateToProps = ({ session, userProfile, permissions }: StoreProps) => ({
    roles: session?.cognitoGroups ?? [],
    userName: session?.user?.username ?? '',
    profile: userProfile.info,
    permissions: permissions?.permissions,
  })

  return withApollo(connect(mapStateToProps)(WithCurrentPermissionHOC))
}
