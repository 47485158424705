import Button from '@berlitz/button'
import { H1, H3 } from '@berlitz/globals'
import Modal from '@berlitz/modal'
import Spacer from '@berlitz/spacer'
import { IModal } from '@components/Modal/interface'
import { CenterText } from '@components/Modal/style'
import Router from 'next/router'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

export const Image = styled.div`
  padding: ${({ theme }) => theme.space.md};
  max-width: 250px;
`

const Maintenance = ({ open }: IModal) => {
  const [openModal, toggleModal] = useState(open)

  const signoutUser = () => {
    toggleModal(false)
    Router.push('/signout')
  }

  return (
    <Modal open={!!openModal} onClose={signoutUser}>
      <CenterText>
        <Image>
          <img src="/images/bug.svg" width={'100%'} />
        </Image>
        <H1 disableMargin>
          <FormattedMessage id="We'll be back soon." />
        </H1>
        <Spacer size="md" />
        <H3>
          <FormattedMessage id="Sorry for the inconvenience. We'll be back up and running as fast as possible." />
        </H3>
        <Button onClick={signoutUser}>
          <FormattedMessage id="Ok" />
        </Button>
      </CenterText>
    </Modal>
  )
}

export default React.memo(Maintenance)
