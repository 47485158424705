import Spacer from '@berlitz/spacer'
import * as React from 'react'
import { injectIntl, WrappedComponentProps } from 'react-intl'

import { SessionProps } from '@interfaces/StoreState'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { Wrapper } from './style'

interface Prop extends SessionProps {
  statusCode: number
}

const Error: React.FC<Prop & WrappedComponentProps> = ({ intl, statusCode, user, isAdmin }) => {
  const heading = {
    400: 'Bad request',
    404: 'Page not found',
    403: 'We are sorry but you do not have access to this page or resource',
  }

  return (
    <Wrapper isAdmin={isAdmin} hasUser={isEmpty(user)}>
      <div>
        <h1>{statusCode}</h1>
        <p>{intl.formatMessage({ id: heading[statusCode] || heading['404'] })}</p>
        <Spacer size="xl" />
      </div>

      <object type="image/svg+xml" data="/images/404.svg" width="100%" style={{ maxWidth: '500px' }} />
    </Wrapper>
  )
}

const mapStateToProps = ({ session }) => ({
  user: session.user,
  isAdmin: session.isAdmin,
})
export default connect(mapStateToProps)(injectIntl(Error))
