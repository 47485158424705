import EmptyState from '@components/EmptyState'
import React from 'react'

const Maintenance = () => (
  <EmptyState
    bodyTxt="Sorry for the inconvenience. We'll be back up and running as fast as possible."
    heading="We'll be back soon."
    img="/images/bug.svg"
  />
)

export default Maintenance
