import { H3 } from '@berlitz/globals'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.sm};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    text-align: center;
    line-height: 1.56;
  }
`

export const Image = styled.div`
  padding: ${({ theme }) => theme.space.md};

  img {
    margin: auto;
  }
`
