import Button from '@berlitz/button'
import { H3, P } from '@berlitz/globals'
import Spacer from '@berlitz/spacer'
import { IEmptyState } from '@components/EmptyState/interface'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl'
import { Image, Wrapper } from './style'

const EmptyState: React.FC<InjectedIntlProps & IEmptyState> = ({
  intl,
  customHeading,
  heading,
  bodyTxt,
  btnTxt,
  linkUrl,
  img,
  children,
  top,
  bottom,
  onProceed,
  isModal,
  withViewCaseBtn = true,
}) => {
  return (
    <>
      {top && <Spacer size={top} />}
      <Wrapper>
        {customHeading ? customHeading : null}
        {heading && (
          <H3>
            <FormattedMessage id={heading} />
          </H3>
        )}
        {bodyTxt && (
          <P>
            <FormattedMessage id={bodyTxt} />
          </P>
        )}

        {img && (
          <Image>
            <img
              src={img}
              alt={intl.formatMessage({ id: heading || 'Berlitz Portal' })}
              width={isModal ? '80%' : '100%'}
            />
          </Image>
        )}
        {withViewCaseBtn && btnTxt && linkUrl && (
          <>
            <Spacer size="md" />
            <Button onClick={() => (onProceed ? onProceed() : Router.push(linkUrl))}>
              <FormattedMessage id={btnTxt} />
            </Button>
          </>
        )}
        {children}
      </Wrapper>
      {bottom && <Spacer size={bottom} />}
    </>
  )
}

export default injectIntl(EmptyState)
